
.top-bar-icons {
  position: absolute;
  margin-top: -4.2rem;
  margin-left: 18rem;
  opacity: 0.7;
  font-size: 3rem !important;
}

.menu-item {
  text-decoration: none;
  text-align: center;
  padding: clamp(0.25rem, 0.5vw, 0.5rem) 0rem;
  transition: opacity 400ms ease;
  color: inherit;
  cursor: auto;
}

#menu-items {
  z-index: 1;
}

/* Animations */
#menu-items:hover > .menu-item {
  opacity: 0.3;
}

#menu-items:hover > .menu-item:hover {
  opacity: 1;
}
