.body {
  flex-direction: row;
  overflow-y: visible;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: -8rem;
  z-index: 1;
}