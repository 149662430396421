
.center-justified {
  text-align: justify;
  text-align-last: left;
  justify-content: center;
  align-items: center;
  position: relative
}

.center-image {
  display: block;
  text-align-last: center;
  margin-left: auto;
  margin-right: auto;
  margin: auto;
}

.bold {
  font-weight: bold;
}
