/* To dim the entire screen */
.dim {
  position: fixed;
  height: 200%;
  width: 200%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
  opacity: 1;
  animation: 250ms fadeIn;
}
/* Defaults visibility when entering page */
.hidden {
  visibility: hidden;
}

/* Handle disabling and enabling screen dimming */
.hidden-transition {
  visibility: hidden;
  opacity: 0;
  animation: 250ms fadeOut;
}

.loading {
  text-align-last: center;
}

.photo {
  transition: transform 400ms;
}

.photo:hover {
  transform: scale(1.02);
}

/* Handles screen dimming animations */
@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: hidden;
  }
  100% {
    opacity: 0;
  }
}